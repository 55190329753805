<template>
  <b-card>
    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-img
            ref="previewEl"
            rounded
            :src="optionsLocal.avatar"
            height="80"
          />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="$refs.refInputEl.$el.click()"
        >
          Trocar foto
        </b-button>
        <b-form-file
          ref="refInputEl"
          v-model="profileFile"
          accept=".jpg, .png, .gif"
          :hidden="true"
          plain
          @input="inputImageRenderer"
        />
        <!--/ upload button -->

        <!-- reset -->
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          size="sm"
          class="mb-75 mr-75"
          @click="resetAvatar"
        >
          Restaurar
        </b-button>
        <!--/ reset -->
        <b-card-text
          >Permitido imagem do tipo JPG, GIF ou PNG. Tamanho máximo de
          800kB</b-card-text
        >
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">
          <b-form-group label="Nome" label-for="account-name">
            <b-form-input
              v-model="optionsLocal.nome"
              placeholder="Nome"
              name="nome"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Sobrenome" label-for="account-sobrenome">
            <b-form-input
              v-model="optionsLocal.sobrenome"
              name="sobrenome"
              placeholder="Sobrenome"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="E-mail" label-for="account-email">
            <b-form-input
              v-model="optionsLocal.email"
              name="email"
              placeholder="E-mail"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="Telefone" label-for="account-telefone">
            <b-form-input
              v-model="optionsLocal.celular"
              name="telefone"
              placeholder="Telefone"
              v-mask="['(##) ####-####', '(##) #####-####']"
            />
          </b-form-group>
        </b-col>

        <!-- alert -->
        <b-col cols="12" class="mt-75" v-if="false">
          <b-alert show variant="warning" class="mb-50">
            <h4 class="alert-heading">
              Your email is not confirmed. Please check your inbox.
            </h4>
            <div class="alert-body">
              <b-link class="alert-link">
                Resend confirmation
              </b-link>
            </div>
          </b-alert>
        </b-col>
        <!--/ alert -->

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click="updateAccount"
          >
            Salvar alterações
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
            @click.prevent="resetForm"
          >
            Restaura padrão
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
      profileFile: null,
    };
  },
  methods: {
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData));
    },
    resetAvatar() {
      this.optionsLocal.avatar = this.generalData.avatar;
      this.profileFile = null;
    },

    async updateAccount() {
      let data = this.optionsLocal;
      try {
        await this.$store.dispatch("account/updateUserInfo", data);
        this.profileFile = null;
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Dados atualizados com sucesso!`,
            icon: "CoffeeIcon",
            variant: "success",
          },
        });
      } catch (e) {
        const { data } = e.response;
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Erro ao atualizar dados!`,
            icon: "CoffeeIcon",
            variant: "red",
            text: data.error,
          },
        });
      }
    },
  },
  watch: {
    previewEl(val) {
      let check = val.src == undefined;
      if (check) {
        this.optionsLocal.avatar = val;
      }
    },

    generalData(val) {
      if (val) this.optionsLocal = JSON.parse(JSON.stringify(val));
    },
  },
  setup() {
    const refInputEl = ref(null);
    const previewEl = ref(null);
    const preview = (preview) => (previewEl.value = preview);
    const { inputImageRenderer } = useInputImageRenderer(refInputEl, preview);

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    };
  },
};
</script>
